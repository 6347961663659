import { DatePipe } from '@angular/common';

import { Endereco } from '../interfaces/Endereco';

let datePipe = new DatePipe('pt-BR');

export function formatarEndereco(endereco: Endereco): string {
    let enderecoFormatado = '';

    if ( endereco == null ) {
        return enderecoFormatado;
    }

    if (endereco.rua != null) {
        enderecoFormatado += endereco.rua;
    }
    if (endereco.numero) {
        enderecoFormatado += `${endereco.rua ? ',' : ''} ${endereco.numero}`;
    }
    if (endereco.bairro) {
        enderecoFormatado += `${endereco.numero ? '-' : ''} ${endereco.bairro}`;
    }
    if (endereco.cidade) {
        enderecoFormatado += `${endereco.bairro ? '-' : ''} ${endereco.cidade}`
    }
    if (endereco.estado) {
        enderecoFormatado += `${endereco.cidade ? '/' : ''} ${endereco.estado}`
    }

    return enderecoFormatado;
}

export function formatarData(data: Date): string {
    return datePipe.transform(data, 'dd/MM/yyyy');
}

export function formatarHora(data: Date): string {
    return datePipe.transform(data, 'H:mm:ss')
}

export function formatarDataHora(data: Date): string {
    return formatarData(data) + " " + formatarHora(data);
}

