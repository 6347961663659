import {Directive, Input, HostBinding} from '@angular/core';

@Directive({
selector: 'img[default]',
})
export class ImagePreloadDirective {
    @Input() src: string;
    @Input() default: string;
    @HostBinding('class') className;

    @HostBinding('error')
    updateUrl() {
        this.src = this.default;
    }

    @HostBinding('load')
    load(){
        this.className = 'image-loaded';
    }
}