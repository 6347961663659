import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { SelectItem } from 'primeng/api';

import { Subscription } from 'rxjs';

import { Push } from 'src/app/shared/interfaces/Push';
import { RepresentanteService } from 'src/app/sistema/services/representante.service';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'dc-enviar-notificacao',
    templateUrl: './enviar-notificacao.component.html',
    styleUrls: ['./enviar-notificacao.component.styl'],
    providers: [
        RepresentanteService
    ]
})
export class EnviarNotificacaoComponent implements OnInit, OnDestroy {

    push: Push;
    filtro: FiltroBarraPesquisa = {};

    @ViewChild('popupEnviarNotificacao', { static: true })
    popupEnviarNotificacao: ElementRef<HTMLDivElement>;

    constructor(
        private representantesService: RepresentanteService,
        private messageService: MessageService
    ) { }

    ngOnInit() {
        this.push = {};
    }

    ngOnDestroy() {
        // this.representantes$.unsubscribe()
    }

    controlarPopupEnviarNotificacao() {
        const popup: HTMLDivElement = this.popupEnviarNotificacao.nativeElement;

        if (popup.style.display === 'none') {
            popup.style.display = 'flex';
            this.push = {};
        } else {
            popup.style.display = 'none';
        }
    }

    enviarMensagem() {
        if(this.push.mensagem == null || this.push.mensagem.length < 1){
            this.messageService.add({
                key: 'toast', 
                severity:'error', 
                summary: 'Erro', 
                detail: 'O campo mensagem precisa estar preenchido!'
            });
            return;
        }

        this.representantesService.enviarNotificacao(this.push).subscribe(				
        response => {
            if(response) {
                this.messageService.add({
                    key: 'toast', 
                    severity:'success', 
                    summary: 'Sucesso', 
                    detail: 'Notificação enviada com sucesso!'
                });
                this.controlarPopupEnviarNotificacao();
            }
        },
        error => {
            this.messageService.add({
                key: 'toast', 
                severity:'error', 
                summary: 'Erro', 
                detail: 'Erro ao enviar notificação.'
            });
        })
        
    }

}
