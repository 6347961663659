import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { LoginComponent } from './login.component';
import { AcessarComponent } from './acessar/acessar.component';
import { EsqueciMinhaSenhaComponent } from './esqueci-minha-senha/esqueci-minha-senha.component';
import { LoginService } from './login.service';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        CheckboxModule,
        MessagesModule,
        MessageModule,
        ToastModule,
    ],
    exports: [],
    declarations: [
        LoginComponent,
        AcessarComponent,
        EsqueciMinhaSenhaComponent
    ],
    providers: [
        LoginService,
        MessageService
    ],
})
export class LoginModule { }
