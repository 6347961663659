import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Representante } from 'src/app/shared/interfaces/Representante';
import { RepresentanteService } from '../services/representante.service';
import { Arquivo } from 'src/app/shared/interfaces/Arquivo';
import { MessageService } from 'primeng/api';

const TAMANHO_MAX_ARQUIVO = 2000;
const TIPO_ACEITO = 'image';

@Component({
    templateUrl: './representante.component.html',
    styleUrls: ['./representante.styl'],
    providers: [
        RepresentanteService
    ]
})
export class RepresentanteComponent implements OnInit {

    idRepresentante: number;
    representante: Representante;
    arquivo: Arquivo;

    @ViewChild('upload', { static: true })
    uploadInput: ElementRef<HTMLInputElement>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private representanteService: RepresentanteService,
        private router: Router,
        private messageService: MessageService
    ) {
        this.idRepresentante = this.activatedRoute.snapshot.params.id;
    }

    ngOnInit() {
        if (this.idRepresentante) {
            this.buscarRepresentante();
        } else {
            this.representante = { endereco: {} };
        }
    }

    voltarRepresentantes() {
        this.router.navigate(['representantes'])
    }

    abrirUpload() {
        this.uploadInput.nativeElement.click();
    }

    converterArquivo(event) {
        const component = this;
        const arquivo = event.target.files[0];

        const splitTipoArquivo = arquivo.type.split("/");

        if (splitTipoArquivo[0] != TIPO_ACEITO) {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Arquivo: ${arquivo.name} não é um formato válido`
            });
            return;
        }

        const tamanhoArquivo = (arquivo.size / 1024);

        if (tamanhoArquivo > TAMANHO_MAX_ARQUIVO) {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Arquivo: ${arquivo.name} é maior que 2MB`
            });
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(arquivo);

        reader.onload = (arquivoConvertido: any) => {
            const dados = arquivoConvertido.target.result.split("base64,");
            this.representante.foto = {
                nome: arquivo.name,
                bytes: dados[1],
                tamanho: arquivo.size,
            }
        }

        reader.onerror = (error) => {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Não foi possivel ler a imagem.`
            });
            console.log('Error: ', error);
        };
    }

    salvarOuEditarRepresentante() {
        if (this.validarCamposRepresentante()) {
            return;
        }
        this.representanteService.saveOrUpdate(this.representante).subscribe(
            response => {
                this.voltarRepresentantes();
            }
        );
    }

    private buscarRepresentante() {
        this.representanteService.buscarRepresentante(this.idRepresentante).subscribe(
            response => {
                this.representante = response;
            }
        );
    }

    private validarCamposRepresentante(): boolean {
        const representante: Representante = this.representante;
        let erro = false;

        if (!representante.email || representante.email === '') {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo E-mail`
            });
            erro = true;
        }
        if (!representante.nome || representante.nome === '') {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Nome`
            });
            erro = true;
        }
        if (!representante.cpfCnpj || representante.cpfCnpj === '' || representante.cpfCnpj.length < 14) {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo CPF corretamente`
            });
            erro = true;
        }
        if (!representante.telefone || representante.telefone === '' || representante.telefone.length < 15) {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Celular corretamente`
            });
            erro = true;
        }
        if (!representante.endereco.rua || representante.endereco.rua === '') {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Rua`
            });
            erro = true;
        }
        if (!representante.endereco.bairro || representante.endereco.bairro === '') {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Bairro`
            });
            erro = true;
        }
        if (!representante.endereco.numero || representante.endereco.numero === '') {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Número`
            });
            erro = true;
        }
        if (!representante.endereco.cidade || representante.endereco.cidade === '') {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Cidade`
            });
            erro = true;
        }
        if (!representante.endereco.uf || representante.endereco.uf === '') {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Estado`
            });
            erro = true;
        }

        if ( representante.senha !== representante.confirmacaoSenha ) {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Senha e confirmação não são iguais`
            });
            erro = true;
        }        

        return erro;
    }

}
