import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { MessageService } from 'primeng/api';
import {PickListModule} from 'primeng/picklist';
import { NgxMaskModule } from 'ngx-mask'

import { SistemaComponent } from './sistema.component';
import { CoreModule } from '../core/core.module';
import { ImagePreloadDirective } from '../shared/directives/ImagePreload.directive';
import { SideBarModule } from '../core/side-bar/side-bar.module';
import { HomeComponent } from './home/home.component';
import { ResumoComponent } from './resumo/resumo.component';
import { HeaderModule } from '../core/header/header.module';
import { ClientesComponent } from './clientes/clientes.component';
import { OrcamentosComponent } from './orcamentos/orcamentos.component';
import { OrcamentoComponent } from './orcamento/orcamento.component';
import { RepresentantesComponent } from './representantes/representantes.component';
import { RepresentanteComponent } from './representante/representante.component';
import { LogsComponent } from './logs/logs.component';
import { ProdutosComponent } from './produtos/produtos.component';
import { ProdutoComponent } from './produto/produto.component';
import { OpcionaisComponent } from './opcionais/opcionais.component';
import { OpcionalComponent } from './opcional/opcional.component';
import { GruposComponent } from './grupos/grupos.component';
import { GrupoComponent } from './grupo/grupo.component';
import { DropdownModule } from 'primeng/dropdown';
import { ItemComponent } from './item-listagem/item-listagem.component';
import { DynamicDialogModule } from 'primeng/components/dynamicdialog/dynamicdialog';
import { PedidosComponent } from './pedidos/pedidos.component';
import { PedidoComponent } from './pedido/pedido.component';
import { ConfiguracaoComponent } from './configuracao/configuracao.component';
import { CheckboxModule } from 'primeng/checkbox';


@NgModule({
    declarations: [
        SistemaComponent,
        ImagePreloadDirective,
        HomeComponent,
        ResumoComponent,
        ClientesComponent,
        ProdutosComponent,
        ProdutoComponent,
        OpcionaisComponent,
        OpcionalComponent,
        GruposComponent,
        GrupoComponent,
        OrcamentosComponent,
        OrcamentoComponent,
        RepresentantesComponent,
        RepresentanteComponent,
        LogsComponent,
        ItemComponent,
        PedidosComponent,
        PedidoComponent,
        ConfiguracaoComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        MessagesModule,
        MessageModule,
        ToastModule,
        SideBarModule,
        HeaderModule,
        TableModule,
        DropdownModule,
        NgxMaskModule.forRoot(),
        PickListModule,
        DynamicDialogModule,
        CheckboxModule
    ],
    entryComponents: [
        ItemComponent
    ],
    providers: [
        MessageService
    ]
})
export class SistemaModule { }
