import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Arquivo } from 'src/app/shared/interfaces/Arquivo';
import { MessageService, SelectItem } from 'primeng/api';
import { PickList } from 'primeng/picklist';
import { ConfiguracaoDto } from 'src/app/shared/interfaces/dto/ConfiguracaoDto';
import { ConfiguracaoService } from '../services/configuracao.service';
import { Configuracao } from 'src/app/shared/interfaces/Configuracao';
import { tipoEtiqueta } from 'src/app/shared/enums/tipoEtiqueta';


@Component({
    templateUrl: './configuracao.component.html',
    styleUrls: ['./configuracao.styl'],
    providers: [
        ConfiguracaoService
    ]
})
export class ConfiguracaoComponent implements OnInit {

    configuracaoDto: ConfiguracaoDto = {};
    configuracaoGeral: Configuracao;
	configuracaoBordada: Configuracao;
	configuracaoAdesiva: Configuracao;
	configuracaoTag: Configuracao;
	configuracaoEstampada: Configuracao;
	configuracaoResinada: Configuracao;
    simNaoSelect: SelectItem[];
    showMe: boolean=true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private configuracaoService: ConfiguracaoService,
        private router: Router,
        private messageService: MessageService
    ) {
        
    }

    ngOnInit() {

        this.buscarConfiguracoes();

		this.simNaoSelect = [
			{
				label: "Sim",
				value: "S"
			},
			{
				label: "Não",
				value: "N"
			}
		]

    }

    private buscarConfiguracoes() {
        this.configuracaoService.buscarConfiguracoes().subscribe(
            response => {
                this.configuracaoDto = response;
                this.configuracaoDto.tipoEtiqueta = tipoEtiqueta.SELECIONE;
                this.configuracaoGeral = this.configuracaoDto.configuracaoGeral;
                this.configuracaoAdesiva = this.configuracaoDto.configuracaoAdesiva;
                this.configuracaoBordada = this.configuracaoDto.configuracaoBordada;
                this.configuracaoEstampada = this.configuracaoDto.configuracaoEstampada;
                this.configuracaoResinada = this.configuracaoDto.configuracaoResinada;
                this.configuracaoTag = this.configuracaoDto.configuracaoTag;
                
            }
        );
    }

    salvarOuEditarConfiguracoes() {
        if (this.validarCamposConfiguracoes()) {
            console.log("Campos inválidos")
            this.messageService.add({
                key: "toast",
                severity: 'warn',
                summary: "Atenção",
                detail: "Por favor, preencha todos os campos corretamente!"
            });
            return;
        }

        console.log(this.configuracaoDto);
        this.configuracaoDto.configuracaoGeral = this.configuracaoGeral;
        this.configuracaoDto.configuracaoAdesiva = this.configuracaoAdesiva;
        this.configuracaoDto.configuracaoBordada = this.configuracaoBordada;
        this.configuracaoDto.configuracaoEstampada = this.configuracaoEstampada;
        this.configuracaoDto.configuracaoResinada = this.configuracaoResinada;
        this.configuracaoDto.configuracaoTag = this.configuracaoTag;

        this.configuracaoService.saveOrUpdate(this.configuracaoDto).subscribe(
            response => {
                if(response.status == 200){
                    this.messageService.add({
                        key: 'toast',
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: `Dados salvos com sucesso!`
                    });
                }
            },
            error => {
                this.messageService.add({
                    key: 'toast',
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Houve um erro ao salvar os dados!'
                });
            }
        );
    }

    private validarCamposConfiguracoes(): boolean {
        let contemErro = "";
        contemErro += this.isValidString(this.configuracaoGeral.emailRecebedorPedido) ? false : true;

        contemErro += this.isValidString(this.configuracaoAdesiva.prazoEntrega) ? false : true;
        contemErro += this.isValidString(this.configuracaoAdesiva.cliche) ? false : true;
        contemErro += this.isValidString(this.configuracaoAdesiva.frete) ? false : true;
        contemErro += this.isValidString(this.configuracaoAdesiva.validadeOrcamento) ? false : true;
        contemErro += this.isValidString(this.configuracaoAdesiva.atencao) ? false : true;

        contemErro += this.isValidString(this.configuracaoBordada.prazoEntrega) ? false : true;
        contemErro += this.isValidString(this.configuracaoBordada.cliche) ? false : true;
        contemErro += this.isValidString(this.configuracaoBordada.frete) ? false : true;
        contemErro += this.isValidString(this.configuracaoBordada.validadeOrcamento) ? false : true;
        contemErro += this.isValidString(this.configuracaoBordada.atencao) ? false : true;
        contemErro += this.isValidNumber(this.configuracaoBordada.fatorMultiplicacaoCalculoBordadas) ? false : true;

        contemErro += this.isValidString(this.configuracaoTag.prazoEntrega) ? false : true;
        contemErro += this.isValidString(this.configuracaoTag.cliche) ? false : true;
        contemErro += this.isValidString(this.configuracaoTag.frete) ? false : true;
        contemErro += this.isValidString(this.configuracaoTag.validadeOrcamento) ? false : true;
        contemErro += this.isValidString(this.configuracaoTag.atencao) ? false : true;

        contemErro += this.isValidString(this.configuracaoResinada.prazoEntrega) ? false : true;
        contemErro += this.isValidString(this.configuracaoResinada.cliche) ? false : true;
        contemErro += this.isValidString(this.configuracaoResinada.frete) ? false : true;
        contemErro += this.isValidString(this.configuracaoResinada.validadeOrcamento) ? false : true;
        contemErro += this.isValidString(this.configuracaoResinada.atencao) ? false : true;

        contemErro += this.isValidString(this.configuracaoEstampada.prazoEntrega) ? false : true;
        contemErro += this.isValidString(this.configuracaoEstampada.cliche) ? false : true;
        contemErro += this.isValidString(this.configuracaoEstampada.frete) ? false : true;
        contemErro += this.isValidString(this.configuracaoEstampada.validadeOrcamento) ? false : true;
        contemErro += this.isValidString(this.configuracaoEstampada.atencao) ? false : true;

        return contemErro.includes('true') ? true : false;
    }

    isValidString(value : string ) : boolean {
        return value == null ? false : value == "" ? false : true;
    }

    isValidNumber(value : any ) : boolean {
        if(value === 0) return true;
        return value == null ? false : value == "" ? false : true;
    }

    mudarForm(){
        console.log(this.configuracaoDto);
    }

}
