import { NgModule, LOCALE_ID } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';

import { LoginComponent } from './login/login.component';
import { AcessarComponent } from './login/acessar/acessar.component';
import { EsqueciMinhaSenhaComponent } from './login/esqueci-minha-senha/esqueci-minha-senha.component';
import { SistemaComponent } from './sistema/sistema.component';
import { AuthenicationGuard } from './core/guard/authentication.guard';
import { HomeComponent } from './sistema/home/home.component';
import { ResumoComponent } from './sistema/resumo/resumo.component';
import ptBr from '@angular/common/locales/pt';
import { ClientesComponent } from './sistema/clientes/clientes.component';
import { OrcamentosComponent } from './sistema/orcamentos/orcamentos.component';
import { OrcamentoComponent } from './sistema/orcamento/orcamento.component';
import { RepresentantesComponent } from './sistema/representantes/representantes.component';
import { RepresentanteComponent } from './sistema/representante/representante.component';
import { LogsComponent } from './sistema/logs/logs.component';
import { ProdutosComponent } from './sistema/produtos/produtos.component';
import { ProdutoComponent } from './sistema/produto/produto.component';
import { OpcionaisComponent } from './sistema/opcionais/opcionais.component';
import { OpcionalComponent } from './sistema/opcional/opcional.component';
import { GruposComponent } from './sistema/grupos/grupos.component';
import { GrupoComponent } from './sistema/grupo/grupo.component';
import { PedidosComponent } from './sistema/pedidos/pedidos.component';
import { PedidoComponent } from './sistema/pedido/pedido.component';
import { ConfiguracaoComponent } from './sistema/configuracao/configuracao.component';

registerLocaleData(ptBr);

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        children: [
            {
                path: '',
                component: AcessarComponent
            },
            {
                path: 'esqueci-minha-senha',
                component: EsqueciMinhaSenhaComponent
            }
        ]
    },
    {
        path: '',
        component: SistemaComponent,
        canActivate: [
            AuthenicationGuard
        ],
        children: [
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'resumo',
                component: ResumoComponent
            },
            {
                path: 'clientes',
                component: ClientesComponent
            },
            {
                path: 'produtos',
                component: ProdutosComponent
            },           
            {
                path: 'produto',
                component: ProdutoComponent
            }, 
            {
                path: 'produto/:id',
                component: ProdutoComponent
            }, 
            {
                path: 'opcionais',
                component: OpcionaisComponent
            },
            {
                path: 'opcional',
                component: OpcionalComponent
            },
            {
                path: 'opcional/:id',
                component: OpcionalComponent
            },
            {
                path: 'grupos',
                component: GruposComponent
            },
            {
                path: 'grupo',
                component: GrupoComponent
            },
            {
                path: 'grupo/:id',
                component: GrupoComponent
            },
            {
                path: 'orcamentos',
                component: OrcamentosComponent
            },
            {
                path: 'orcamento',
                component: OrcamentoComponent
            },
            {
                path: 'orcamento/:id',
                component: OrcamentoComponent
            },
            {
                path: 'pedidos',
                component: PedidosComponent
            },
            {
                path: 'pedido',
                component: PedidoComponent
            },
            {
                path: 'pedido/:id',
                component: PedidoComponent
            },
            {
                path: 'representantes',
                component: RepresentantesComponent
            },
            {
                path: 'representante',
                component: RepresentanteComponent
            },
            {
                path: 'representante/:id',
                component: RepresentanteComponent
            },
            {
                path: 'logs',
                component: LogsComponent
            },
            {
                path: 'configuracoes',
                component: ConfiguracaoComponent
            },             
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'top'
    })],
    exports: [RouterModule],
    providers: [{ provide: LOCALE_ID, useValue: 'pt' }],
})
export class AppRoutingModule { }
