import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Column } from 'src/app/shared/interfaces/Column';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Representante } from 'src/app/shared/interfaces/Representante';
import { formatarData, formatarHora } from 'src/app/shared/util/formatters';
import { LogService } from '../services/log.service';
import { Log } from 'src/app/shared/interfaces/Log';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';

@Component({
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.styl'],
    providers: [
        LogService
    ]
})
export class LogsComponent implements OnInit {

    colunas: Column[];
    logsPagina: Paginacao<Log>;
    logs$: Subscription;
    filtro: FiltroBarraPesquisa = {};

    constructor(
        private logService: LogService
    ) { }

    ngOnInit() {
        this.colunas = [
            {
                field: 'dataProximoEnvio',
                header: 'DATA',
                format: this.formatarDataHora
            },
            {
                field: 'nomeRepresentante',
                header: 'TIPO',
                format: this.formatarTipoIntegracao
            },
            {
                field: 'data.idOrigem',
                header: 'CODIGO',
                format: this.formatarCodigo
            },
            {
                field: 'status',
                header: 'STATUS',
            },
            {
                field: 'usuarioMensagem',
                header: 'ERRO',
                format: this.formatarErro
            }
        ];
        this.buscarLogs(this.filtro, 0);
    }

    loadDataOnScroll(event) {
        console.log(event);
        this.buscarLogs(this.filtro, event.first / event.rows);
    }

    private formatarDataHora(log: Log): string {
        return formatarData(log.dataProximoEnvio) + " " + formatarHora(log.dataProximoEnvio)
    }
    private formatarTipoIntegracao(log: Log): string {
        return log.data.tipo == "P" ? "Produto" : "Representante";
    }
    private formatarCodigo(log: Log): string {
        return log.data.idOrigem;
    }    
    private formatarErro(log: Log): string {
        if(log.usuarioMensagem != null){
            return log.usuarioMensagem.length > 50 ? log.usuarioMensagem.substring(0, 50) + " ..." : log.usuarioMensagem;
        }
           return log.usuarioMensagem;
    }

    public buscarLogs = (filtro: FiltroBarraPesquisa, pagina: number) => {
        this.logService.buscarLogs(filtro, pagina).subscribe(
            response => {
                this.logsPagina = response;
            }
        );
    }

}
