import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Cliente } from 'src/app/shared/interfaces/Cliente';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';

@Injectable()
export class ClientesService {

    constructor(private http: HttpClient) { }

    buscarClientes(filtro: FiltroBarraPesquisa, pagina:number): Observable<Paginacao<Cliente>> {
        return this.http.post<Paginacao<Cliente>>(environment.URL_BASE + "WS/Cliente/Filtrar?page="+pagina, filtro);
    }

}
