import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { TokenService } from '../token/token.service';

@Injectable({providedIn: 'root'})
export class AuthenicationGuard implements CanActivate {
    constructor(
        private tokenService: TokenService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(this.tokenService.hasToken()) {
            return true;
        }
        this.router.navigate(['login']);
        return false;
    }
}