import { Component, OnInit } from '@angular/core';
import { Column } from 'src/app/shared/interfaces/Column';
import { Subscription } from 'rxjs';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Representante } from 'src/app/shared/interfaces/Representante';
import { formatarEndereco } from 'src/app/shared/util/formatters';
import { RepresentanteService } from '../services/representante.service';
import { Router } from '@angular/router';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';

@Component({
    templateUrl: './representantes.component.html',
    styleUrls: ['./representantes.styl'],
    providers: [
        RepresentanteService
    ]
})
export class RepresentantesComponent implements OnInit {

    colunas: Column[];
    representantesPagina: Paginacao<Representante> = {};
    representantes$: Subscription;
    filtro: FiltroBarraPesquisa = {};

    constructor(
        private representantesService: RepresentanteService,
        private router: Router
    ) { }

    ngOnInit() {
        this.colunas = [
            {
                field: 'nome',
                header: 'REPRESENTANTE'
            },
            {
                field: 'telefone',
                header: 'TELEFONE',
            },
            {
                field: 'cpfCnpj',
                header: 'CPF',
            },
            {
                field: 'email',
                header: 'E-MAIL',
            },
        ];
        this.buscarRepresentantes(this.filtro, 0);
    }

    loadDataOnScroll(event) {
        this.buscarRepresentantes(this.filtro, event.first / event.rows);
    }

    editarRepresentante(representante: Representante) {
        this.router.navigate(['representante', representante.id]);
    }

    cadastrarNovoRepresentante() {
        this.router.navigate(['representante']);
    }

    public buscarRepresentantes = (filtro: FiltroBarraPesquisa, pagina: number) => {
        this.representantesService.buscarRepresentantes(filtro, pagina).subscribe(
            response => {
                this.representantesPagina = response;
                console.log(this.representantesPagina);
            }
        );
    }

}
