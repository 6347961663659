import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';

import { Integracao } from 'src/app/shared/interfaces/Integracao';
import { IntegracaoService } from './integracao.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dc-menu-integracao',
    templateUrl: './menu-integracao.component.html',
    styleUrls: ['./menu-integracao.component.styl'],
    providers: [
        IntegracaoService
    ]
})
export class MenuIntegracaoComponent implements OnInit, OnDestroy {

    integracao: Integracao;
    integrcao$: Subscription;

    @ViewChild('menuIntegracao', { static: true })
    menuIntegrcao: ElementRef<HTMLDivElement>;

    @ViewChild('botaoAbrir', { static: true })
    botaoAbrir: ElementRef<HTMLButtonElement>;

    constructor(
        private integracaoService: IntegracaoService,
        private messageService: MessageService
    ) { }

    ngOnInit() {
        this.integracao = {};
        this.buscarInformacoesIntegracao();
    }

    ngOnDestroy(): void {
        // this.integrcao$.unsubscribe();
    }

    abrirFecharMenu() {
        const menu: HTMLDivElement = this.menuIntegrcao.nativeElement;
        const botao: HTMLButtonElement = this.botaoAbrir.nativeElement;

        if (menu.classList.contains('ativo')) {
            menu.classList.remove('ativo');
            botao.classList.remove('ativo');
        } else {
            menu.classList.add('ativo');
            botao.classList.add('ativo');
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    guardarConfiguracoes() {
        this.integracaoService.salvarIntegracao(this.integracao)
            .subscribe(
                response => {
                    if (response) {
                        this.messageService.add({
                            key: 'toast',
                            severity: 'success',
                            summary: 'Sucesso',
                            detail: 'Informações salvas com sucesso.'
                        });
                        this.abrirFecharMenu();
                    }
                },
                error => {
                    this.messageService.add({
                        key: 'toast',
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Não foi possivel salvar as informações.'
                    });
                }
            )
            ;
    }

    private buscarInformacoesIntegracao() {
        this.integrcao$ = this.integracaoService.buscarIntegracao()
            .subscribe(
                response => {
                    this.integracao = response;
                }
            )
            ;
    }

}
