import { Component, OnInit } from '@angular/core';
import { Column } from 'src/app/shared/interfaces/Column';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Orcamento } from 'src/app/shared/interfaces/Orcamento';
import { Router } from '@angular/router';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { PedidoService } from '../services/pedido.service';
import { SimNao } from 'src/app/shared/enums/SimNao';

@Component({
    templateUrl: './pedidos.component.html',
    styleUrls: ['./pedidos.styl'],
    providers: [
        PedidoService
    ]
})
export class PedidosComponent implements OnInit {

    colunas: Column[];
    pedidosPagina: Paginacao<Orcamento> = {};
    pedido$: Subscription;
    filtro: FiltroBarraPesquisa = {};

    constructor(
        private pedidoService: PedidoService,
        private router: Router
    ) { }

    ngOnInit() {
        this.colunas = [
            {
                field: 'dataInclusao',
                header: 'DATA'
            },
            {
                field: 'hora',
                header: 'HORÁRIO',
            },
            {
                field: 'numeroOrcamento',
                header: 'PEDIDO',
            },
            {
                field: 'nomeCliente',
                header: 'CLIENTE',
            },
            {
                field: 'nomeRepresentante',
                header: 'REPRESENTANTE',
            },
            {
                field: 'status',
                header: 'STATUS',
            },
        ];
        this.buscarPedidos(this.filtro, 0);
    }

    loadDataOnScroll(event) {
        this.buscarPedidos(this.filtro, event.first / event.rows);
    }

    verPedido(pedido: Orcamento) {
        this.router.navigate(['pedido', pedido.id]);
    }

    
    verPdf(pedidoPdf: Orcamento) {
        window.open(environment.URL_BASE + "WS/Orcamento/Pdf/?id=" + pedidoPdf.id);
    }

    public buscarPedidos = (filtro: FiltroBarraPesquisa, pagina: number) => {
        filtro.sePedidoEmitido = SimNao.Sim;
        this.pedidoService.buscarPedidos(filtro, pagina).subscribe(
            response => {
                this.pedidosPagina = response;
                console.log(this.pedidosPagina);
            }
        );
    }

}
