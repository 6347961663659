import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Usuario } from 'src/app/shared/interfaces/Usuario';

const usuarioId = 'usuarioId';
const usuarioLogado = 'usuarioLogado';

@Injectable({ providedIn: 'root' })
export class UsuarioService {

    URL: string;

    constructor(
        private http: HttpClient
    ) {
        this.URL = environment.URL_BASE;
    }
    
    setUsuarioId(id: number) {
        window.localStorage.setItem(usuarioId, id.toString());
    }

    getUsuarioId(): number {
        return parseInt(window.localStorage.getItem(usuarioId));
    }

    buscarUsuarioPorId(): Observable<any> {
        return this.http.get(`${this.URL}WS/UsuarioWalentex/${this.getUsuarioId()}`);
    }

    setUsuarioLogado(usuario: Usuario) {
        window.localStorage.setItem(usuarioLogado, JSON.stringify(usuario));
    }

    getUsuarioLogado(): Usuario {
        return JSON.parse(window.localStorage.getItem(usuarioLogado));
    }

    removeUsuarioLogado() {
        window.localStorage.removeItem(usuarioLogado);
    }

}