import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Produto } from 'src/app/shared/interfaces/Produto';
import { ProdutoService } from '../services/produto.service';
import { Arquivo } from 'src/app/shared/interfaces/Arquivo';
import { MessageService, SelectItem } from 'primeng/api';
import { PickList } from 'primeng/picklist';
import { GrupoService } from '../services/grupo.service';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Grupo } from 'src/app/shared/interfaces/Grupo';
import { ProdutoDto } from 'src/app/shared/interfaces/dto/ProdutoDto';
import { ProdutoLargura } from 'src/app/shared/interfaces/ProdutoLargura';
import { ProdutoAltura } from 'src/app/shared/interfaces/ProdutoAltura';

const TAMANHO_MAX_ARQUIVO = 2000;
const TIPO_ACEITO = 'image';

@Component({
    templateUrl: './produto.component.html',
    styleUrls: ['./produto.styl'],
    providers: [
        ProdutoService,
        GrupoService
    ]
})
export class ProdutoComponent implements OnInit {

    idProduto: number;
    produto: Produto;
    grupos: Grupo[] = [];
    arquivo: Arquivo;
    produtoDto: ProdutoDto = {};
    simNaoSelect: SelectItem[];
    showMe: boolean=true;
    produtoLargura: ProdutoLargura = {};
    produtoAltura: ProdutoAltura = {};

    @ViewChild('upload', { static: true })
    uploadInput: ElementRef<HTMLInputElement>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private produtoService: ProdutoService,
        private gruposService: GrupoService,
        private router: Router,
        private messageService: MessageService
    ) {
        this.idProduto = this.activatedRoute.snapshot.params.id;
        
        this.produtoDto.grupos = [];
        this.produtoDto.larguras = [];
        this.produtoDto.alturas = [];
        this.buscarGrupos();
    }

    ngOnInit() {

        if (this.idProduto) {
            this.buscarProduto();
        } else {
            this.produto = {};
        }

		this.simNaoSelect = [
			{
				label: "Sim",
				value: "S"
			},
			{
				label: "Não",
				value: "N"
			}
		]

    }

    adicionarLargura (produtoLargura) {
        const component = this;
        if (produtoLargura.largura != null && produtoLargura.largura != "" && 
            ( ( this.produto.tipoEtiqueta != "ESTAMPADA") || (this.produto.tipoEtiqueta == "ESTAMPADA" && produtoLargura.precoMetro != null && produtoLargura.precoMetro != "") )){
            this.produtoDto.larguras.push(produtoLargura);
            this.produtoLargura = {};
        } else {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Por favor informe os dados de largura produto!`
            });
            return;
        }
        
    }
    removerLargura (produtoLargura) {
        let index = this.produtoDto.larguras.indexOf(produtoLargura);
        this.produtoDto.larguras.splice(index, 1);
    }

    adicionarAltura (produtoAltura) {
        const component = this;
        if ( produtoAltura.altura != null && produtoAltura.altura != "" && 
                ( (this.produto.tipoEtiqueta != "ESTAMPADA") || (this.produto.tipoEtiqueta == "ESTAMPADA" && produtoAltura.quantidadePorMetro != null && produtoAltura.quantidadePorMetro != "") )) {
            this.produtoDto.alturas.push(produtoAltura);
            this.produtoAltura = {};
        } else {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Por favor informe os dados de altura do produto!`
            });
            return;
        }
    }
    removerAltura (produtoAltura) {
        let index = this.produtoDto.alturas.indexOf(produtoAltura);
        this.produtoDto.alturas.splice(index, 1);
    }

    voltarProdutos() {
        this.router.navigate(['produtos'])
    }

    abrirUpload() {
        this.uploadInput.nativeElement.click();
    }

    converterArquivo(event) {
        const component = this;
        const arquivo = event.target.files[0];

        const splitTipoArquivo = arquivo.type.split("/");

        if (splitTipoArquivo[0] != TIPO_ACEITO) {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Arquivo: ${arquivo.name} não é um formato válido`
            });
            return;
        }

        const tamanhoArquivo = (arquivo.size / 1024);

        if (tamanhoArquivo > TAMANHO_MAX_ARQUIVO) {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Arquivo: ${arquivo.name} é maior que 2MB`
            });
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(arquivo);

        reader.onload = (arquivoConvertido: any) => {
            const dados = arquivoConvertido.target.result.split("base64,");
            this.produto.foto = {
                nome: arquivo.name,
                bytes: dados[1],
                tamanho: arquivo.size,
            }
        }

        reader.onerror = (error) => {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Não foi possivel ler a imagem.`
            });
            console.log('Error: ', error);
        };
    }

    salvarOuEditarProduto() {
        if (this.validarCamposProduto()) {
            return;
        }
        console.log(this.produto);
        this.produtoDto.produto = this.produto;
        this.produtoService.saveOrUpdate(this.produtoDto).subscribe(
            response => {
                this.voltarProdutos();
            }
        );
    }

    private buscarProduto() {
        this.produtoService.buscarProduto(this.idProduto).subscribe(
            response => {
                this.produtoDto = response;
                this.produto = this.produtoDto.produto;

                this.produtoDto.grupos.forEach((element) => {
                    let index = this.grupos.findIndex(el => el.id === element.id);
                    this.grupos.splice(index, 1);
                });
            }
        );
    }

    private buscarGrupos() {
        this.gruposService.buscarGruposDetalhado({},0, 1000).subscribe(
            response => {
                this.grupos = response.content;
            }
        );
    }

    private validarCamposProduto(): boolean {
        const produto: Produto = this.produto;
        let erro = false;

        if ( (produto.tipoEtiqueta == 'TAG' || produto.tipoEtiqueta == 'ADESIVA') && (!produto.quantidadeColunas || produto.quantidadeColunas === 0)) {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Fator Multiplicador corretamente!`
            });
            erro = true;
        }

        if ( (produto.tipoEtiqueta == 'TAG' || produto.tipoEtiqueta == 'ADESIVA') && (!produto.larguraBobina || produto.larguraBobina === 0)) {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Largura da Bobina corretamente!`
            });
            erro = true;
        }

        if ( (produto.tipoEtiqueta == 'TAG') && (!produto.sangriaCorte || produto.sangriaCorte === 0)) {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Tamanho da Sangria corretamente!`
            });
            erro = true;
        }

        return erro;
    }

    excluir() {
        this.produtoService.excluir(this.idProduto).subscribe(
            response => {
                this.voltarProdutos();
            }
        );
    }    
    
    mudarForm(){
        console.log(this.produto);
    }

    adicionarGrupos(event: any) {

        event.preventDefault();

    }
    
    removerGrupos(event: any) {

        console.log(event)
        console.log("remover");

    }
}
