import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Opcional } from 'src/app/shared/interfaces/Opcional';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { ProdutoDetalhe } from 'src/app/shared/interfaces/ProdutoDetalhe';
import { OpcionalDto } from 'src/app/shared/interfaces/OpcionalDto';


@Injectable()
export class OpcionalService {

    constructor(private http: HttpClient) { }

    buscarOpcionais(filtro: FiltroBarraPesquisa, pagina : number): Observable<Paginacao<Opcional>> {
        return this.http.post<Paginacao<Opcional>>(environment.URL_BASE + "WS/Opcional/Filtrar?page=" + pagina, filtro);
    }

    buscarOpcionaisDto(filtro: FiltroBarraPesquisa, pagina : number): Observable<Paginacao<OpcionalDto>> {
        return this.http.post<Paginacao<OpcionalDto>>(environment.URL_BASE + "WS/Opcional/Filtrar/Dto?page=" + pagina, filtro);
    }

    buscarOpcional(id : number): Observable<Opcional> {
        return this.http.get<Opcional>(environment.URL_BASE + "WS/Opcional/" + id, {});
    }

    excluir(id : number): Observable<Opcional> {
        return this.http.delete<Opcional>(environment.URL_BASE + "WS/Opcional/" + id);
    }

    saveOrUpdate(opcional:Opcional): Observable<Opcional> {
        return this.http.post<Opcional>(environment.URL_BASE + "WS/Opcional", opcional);
    }

}
