import { Component, OnInit } from '@angular/core';
import { Column } from 'src/app/shared/interfaces/Column';
import { Subscription } from 'rxjs';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { OpcionalService } from '../services/opcional.service';
import { Router } from '@angular/router';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { Opcional } from 'src/app/shared/interfaces/Opcional';
import { OpcionalDto } from 'src/app/shared/interfaces/OpcionalDto';
import { GrupoService } from '../services/grupo.service';
import { Grupo } from 'src/app/shared/interfaces/Grupo';

@Component({
    templateUrl: './opcionais.component.html',
    styleUrls: ['./opcionais.styl'],
    providers: [
        OpcionalService,
        GrupoService
    ]
})
export class OpcionaisComponent implements OnInit {

    colunas: Column[];
    opcionaisPagina: Paginacao<OpcionalDto> = {};
    opcionais$: Subscription;
    filtro: FiltroBarraPesquisa = {};
    grupoOptions: Grupo[];

    constructor(
        private opcionalService: OpcionalService,
        private router: Router,
        private grupoService: GrupoService
    ) { }


    private buscarGrupos() {
        this.grupoService.buscarTodosGrupos().subscribe(
            response => {
                this.grupoOptions = response;
            }
        );
    }

    ngOnInit() {
        this.colunas = [
            {
                field: 'descricao',
                header: 'DESCRICAO',
            },
            {
                field: 'descricaoGrupo',
                header: 'GRUPO',
            },            
            {
                field: 'porcentagemAcrescimoOpcional',
                header: 'PORCENTAGEM ACRESCIMO',
            },
            {
                field: 'valorAcrescimoOpcional',
                header: 'VALOR ACRESCIMO',
            }
        ];
        this.buscarOpcionais(this.filtro,0);
        this.buscarGrupos();
    }

    buscar() {
        this.buscarOpcionais(this.filtro,0);
    }

    loadDataOnScroll(event) {
        this.buscarOpcionais(this.filtro, event.first / event.rows);
    }

    editarOpcional(opcional: Opcional) {
        this.router.navigate(['opcional', opcional.id]);
    }

    cadastrarNovoOpcional() {
        this.router.navigate(['opcional']);
    }

    public buscarOpcionais = (filtro: FiltroBarraPesquisa, pagina : number) => {
        this.opcionalService.buscarOpcionaisDto(filtro, pagina).subscribe(
            response => {
                console.log(response);
                this.opcionaisPagina = response;
            }
        );
    }

}
