import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Opcional } from 'src/app/shared/interfaces/Opcional';
import { OpcionalService } from '../services/opcional.service';
import { GrupoService } from '../services/grupo.service';
import { Grupo } from 'src/app/shared/interfaces/Grupo';
import { MessageService, SelectItem } from 'primeng/api';

const TAMANHO_MAX_ARQUIVO = 2000;
const TIPO_ACEITO = 'image';

@Component({
    templateUrl: './opcional.component.html',
    styleUrls: ['./opcional.styl'],
    providers: [
        OpcionalService,
        GrupoService
    ]
})
export class OpcionalComponent implements OnInit {

    idOpcional: number;
    opcional: Opcional;
    grupoOptions: Grupo[];
    simNaoSelect: SelectItem[];

    @ViewChild('upload', { static: true })
    uploadInput: ElementRef<HTMLInputElement>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private opcionalService: OpcionalService,
        private grupoService: GrupoService,
        private router: Router,
        private messageService: MessageService
    ) {
        this.idOpcional = this.activatedRoute.snapshot.params.id;
    }

    ngOnInit() {
        if (this.idOpcional) {
            this.buscarOpcional();
            this.buscarGrupos();
        } else {
            this.opcional = {};
            this.buscarGrupos();
        }

		this.simNaoSelect = [
			{
				label: "Sim",
				value: "S"
			},
			{
				label: "Não",
				value: "N"
			}
		]

    }

    voltarOpcionais() {
        this.router.navigate(['opcionais'])
    }

    abrirUpload() {
        this.uploadInput.nativeElement.click();
    }

    salvarOuEditarOpcional() {
        if (this.validarCamposOpcional()) {
            return;
        }
        this.opcionalService.saveOrUpdate(this.opcional).subscribe(
            response => {
                this.voltarOpcionais();
            }
        );
    }

    excluir() {
        this.opcionalService.excluir(this.idOpcional).subscribe(
            response => {
                this.voltarOpcionais();
            }
        );
    }    

    private buscarOpcional() {
        this.opcionalService.buscarOpcional(this.idOpcional).subscribe(
            response => {
                this.opcional = response;
            }
        );
    }

    private buscarGrupos() {
        this.grupoService.buscarTodosGrupos().subscribe(
            response => {
                this.grupoOptions = response;
                console.log(response);
            }
        );
    }

    private validarCamposOpcional(): boolean {
        const opcional: Opcional = this.opcional;
        let erro = false;

        if ( !opcional.descricaoOpcional || opcional.descricaoOpcional === "") {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Descrição corretamente!`
            });
            erro = true;
        }

        if ( !opcional.grupoOpcionalId || opcional.grupoOpcionalId === 0) {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Selecione um Grupo!`
            });
            erro = true;
        }

        if (  ( opcional.valorAcrescimoOpcional == null) && ( opcional.porcentagemAcrescimoOpcional == null) ) {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o Acrécimo do Opcional corretamente!`
            });
            erro = true;
        }

        return erro;
    }

    onValorChange() {
        this.opcional.porcentagemAcrescimoOpcional = null;
    }

    onPercentualChange() {
        this.opcional.valorAcrescimoOpcional = null;
    }

}
