import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Representante } from 'src/app/shared/interfaces/Representante';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { Push } from 'src/app/shared/interfaces/Push';
import { Response } from 'src/app/shared/interfaces/Response';


@Injectable()
export class RepresentanteService {

    constructor(private http: HttpClient) {
     
     
    
    }
    buscarRepresentantes(filtro: FiltroBarraPesquisa, pagina: number): Observable<Paginacao<Representante>> {
        return this.http.post<Paginacao<Representante>>(environment.URL_BASE + "WS/Representante/Filtrar?page="+pagina, filtro);
    }

    buscarTodosRepresentantes(): Observable<Representante[]> {
        return this.http.get<Representante[]>(environment.URL_BASE + "WS/Representante/Todos");
    }

    buscarRepresentante(id : number): Observable<Representante> {
        return this.http.get<Representante>(environment.URL_BASE + "WS/Representante/" + id + "/Perfil", {});
    }

    saveOrUpdate(representante:Representante): Observable<Representante> {
        return this.http.post<Representante>(environment.URL_BASE + "WS/Representante/", representante);
    }

    enviarNotificacao(push:Push){
    return this.http.post<HttpResponse<Push>>(environment.URL_BASE + "WS/NotificacaoApp/Enviar/Todos", push, {observe: 'response'})
        
    }
}
