import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.styl'],
    providers: []
})
export class LoginComponent implements OnInit{

    constructor() {}

    ngOnInit() {
        
    }

}