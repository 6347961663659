import { Component, Input, OnInit } from '@angular/core';
import { Column } from 'src/app/shared/interfaces/Column';
import { Subject, Subscription } from 'rxjs';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Produto } from 'src/app/shared/interfaces/Produto';
import { ProdutoService } from '../services/produto.service';
import { Router } from '@angular/router';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { tipoEtiqueta } from 'src/app/shared/enums/tipoEtiqueta';
import { SelectItem } from 'primeng/api';

@Component({
    templateUrl: './produtos.component.html',
    styleUrls: ['./produtos.styl'],
    providers: [
        ProdutoService
    ]
})
export class ProdutosComponent implements OnInit {

    colunas: Column[];
    produtosPagina: Paginacao<Produto> = {};
    produtos$: Subscription;
    filtro: FiltroBarraPesquisa = {};
	txtQueryChanged: Subject<string> = new Subject<string>();
	txtBuscaLivre: string; // bind this to input with ngModel
    tipoEtiqueta: tipoEtiqueta;
    tipos: SelectItem[];
	@Input() onChange: (filtro: FiltroBarraPesquisa, pagina) => {};

    constructor(
        private produtosService: ProdutoService,
        private router: Router
    ) { 

        this.tipos = [
			{label:'Todas', value:null},
			{label:'Adesiva', value:tipoEtiqueta.ADESIVA},
            {label:'Tag', value:tipoEtiqueta.TAG},
            {label:'Bordada', value:tipoEtiqueta.BORDADA},
            {label:'Estampada', value:tipoEtiqueta.ESTAMPADA},
        ];
    }

    ngOnInit() {
        this.colunas = [
            {
                field: 'id',
                header: 'CODIGO'
            },
            {
                field: 'descricao',
                header: 'DESCRICAO',
            },
            {
                field: 'larguraBobina',
                header: 'LARGURA BOBINA',
            },
            {
                field: 'quantidadeColunas',
                header: 'MULTIPLICADOR',
            },
            {
                field: 'sangriaCorte',
                header: 'TAMANHO SANGRIA',
            },
        ];

        this.filtro = {};

        this.buscarProdutos(this.filtro,0);
    }

    loadDataOnScroll(event) {
        this.buscarProdutos(this.filtro, event.first / event.rows);
    }

    editarProduto(produto: Produto) {
        this.router.navigate(['produto', produto.id]);
    }

    cadastrarNovoProduto() {
        this.router.navigate(['produto']);
    }

    public buscar = () => {
        this.buscarProdutos(this.filtro, 0);
    }

    public buscarProdutos = (filtro: FiltroBarraPesquisa, pagina : number) => {
        this.produtosService.buscarProdutos(filtro, pagina).subscribe(
            response => {
                this.produtosPagina = response;
            }
        );
    }

}
