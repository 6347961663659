import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	templateUrl: './sistema.component.html',
	styleUrls: ['./sistema.styl']
})
export class SistemaComponent implements OnInit {

	constructor(
		private title: Title
	) {}

	ngOnInit() {
		this.title.setTitle('Nova Etiquetas');
	}

}
