import { Component, OnInit } from '@angular/core';
import { Column } from 'src/app/shared/interfaces/Column';
import { Subscription } from 'rxjs';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Grupo } from 'src/app/shared/interfaces/Grupo';
import { GrupoService } from '../services/grupo.service';
import { Router } from '@angular/router';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';

@Component({
    templateUrl: './grupos.component.html',
    styleUrls: ['./grupos.styl'],
    providers: [
        GrupoService
    ]
})
export class GruposComponent implements OnInit {

    colunas: Column[];
    gruposPagina: Paginacao<Grupo> = {};
    gruposs$: Subscription;
    filtro: FiltroBarraPesquisa = {};

    constructor(
        private grupoService: GrupoService,
        private router: Router
    ) { }

    ngOnInit() {
        this.colunas = [
            {
                field: 'id',
                header: 'CÓDIGO'
            },
            {
                field: 'descricao',
                header: 'DESCRICAO',
            }
        ];
        this.buscarGrupos(this.filtro,0);
    }

    loadDataOnScroll(event) {
        this.buscarGrupos(this.filtro, event.first / event.rows);
    }

    editarGrupo(grupo: Grupo) {
        this.router.navigate(['grupo', grupo.id]);
    }

    cadastrarNovoGrupo() {
        this.router.navigate(['grupo']);
    }

    public buscarGrupos = (filtro: FiltroBarraPesquisa, pagina : number) => {
        this.grupoService.buscarGrupos(filtro, pagina).subscribe(
            response => {
                console.log(response);
                this.gruposPagina = response;
            }
        );
    }

}
