import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse }   from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { TokenService } from './token.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenIsInvalidInterceptor implements HttpInterceptor {

    constructor(
        private tokenService: TokenService,
        private router: Router
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
    
        return next.handle(req).pipe(
            tap(resp => {}, error=>{
                console.log(error);
                if(error.status === 401 || error.status === 403) {
                    this.tokenService.removeToken();
                    this.router.navigate(['login']);
                }
                
                return of(error);
            }));
    
    }
}