import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Orcamento } from 'src/app/shared/interfaces/Orcamento';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { Push } from 'src/app/shared/interfaces/Push';
import { Response } from 'src/app/shared/interfaces/Response';
import { ItemOrcamento } from 'src/app/shared/interfaces/ItemOrcamento';


@Injectable()
export class OrcamentoService {

    constructor(private http: HttpClient) {}
    
    buscarOrcamentos(filtro: FiltroBarraPesquisa, pagina: number): Observable<Paginacao<Orcamento>> {
        return this.http.post<Paginacao<Orcamento>>(environment.URL_BASE + "WS/Orcamento/Filtrar?page="+pagina, filtro);
    }

    buscarTodosOrcamentos(): Observable<Orcamento[]> {
        return this.http.get<Orcamento[]>(environment.URL_BASE + "WS/Orcamento/Todos");
    }

    buscarOrcamento(id : number): Observable<Orcamento> {
        return this.http.get<Orcamento>(environment.URL_BASE + "WS/Orcamento/Completo/" + id, {});
    }

    saveOrUpdate(orcamento:Orcamento): Observable<Orcamento> {
        return this.http.post<Orcamento>(environment.URL_BASE + "WS/Orcamento/Perfil", orcamento);
    }

    enviarNotificacao(push:Push){
        return this.http.post<HttpResponse<Push>>(environment.URL_BASE + "WS/NotificacaoApp/Enviar", push, {observe: 'response'})

    }

    buscarDetalhesItemOrcamento(id : number): Observable<ItemOrcamento> {
        return this.http.get<ItemOrcamento>(environment.URL_BASE + "WS/ItemOrcamento/Detalhes/" + id, {});
    }
}
