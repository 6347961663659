import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Grupo } from 'src/app/shared/interfaces/Grupo';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { ProdutoDetalhe } from 'src/app/shared/interfaces/ProdutoDetalhe';


@Injectable()
export class GrupoService {

    constructor(private http: HttpClient) { }

    buscarGrupos(filtro: FiltroBarraPesquisa, pagina : number): Observable<Paginacao<Grupo>> {
        return this.http.post<Paginacao<Grupo>>(environment.URL_BASE + "WS/GrupoOpcional/Filtrar?page=" + pagina, filtro);
    }

    buscarGruposDetalhado(filtro: FiltroBarraPesquisa, pagina : number, quantidade : number): Observable<Paginacao<Grupo>> {
        return this.http.post<Paginacao<Grupo>>(environment.URL_BASE + "WS/GrupoOpcional/Filtrar?page=" + pagina +"&size=" + quantidade, filtro);
    }

    buscarTodosGrupos(): Observable<Grupo[]> {
        return this.http.get<Grupo[]>(environment.URL_BASE + "WS/GrupoOpcional");
    }

    buscarGrupo(id : number): Observable<Grupo> {
        return this.http.get<Grupo>(environment.URL_BASE + "WS/GrupoOpcional/" + id, {});
    }

    saveOrUpdate(grupo:Grupo): Observable<Grupo> {
        return this.http.post<Grupo>(environment.URL_BASE + "WS/GrupoOpcional", grupo);
    }

    excluir(id : number): Observable<Grupo> {
        return this.http.delete<Grupo>(environment.URL_BASE + "WS/GrupoOpcional/" + id);
    }

}
