import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Produto } from 'src/app/shared/interfaces/Produto';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { ProdutoDetalhe } from 'src/app/shared/interfaces/ProdutoDetalhe';
import { ProdutoDto } from 'src/app/shared/interfaces/dto/ProdutoDto';


@Injectable()
export class ProdutoService {

    constructor(private http: HttpClient) { }

    buscarProdutos(filtro: FiltroBarraPesquisa, pagina : number): Observable<Paginacao<Produto>> {
        return this.http.post<Paginacao<Produto>>(environment.URL_BASE + "WS/Produto/Filtrar?page=" + pagina, filtro);
    }

    buscarProduto(id : number): Observable<ProdutoDto> {
        return this.http.get<ProdutoDto>(environment.URL_BASE + "WS/Produto/" + id, {});
    }

    saveOrUpdate(produto:ProdutoDto): Observable<ProdutoDto> {
        return this.http.post<ProdutoDto>(environment.URL_BASE + "WS/Produto", produto);
    }

    excluir(id : number): Observable<Produto> {
        return this.http.delete<Produto>(environment.URL_BASE + "WS/Produto/" + id);
    }

}
