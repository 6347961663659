import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Grupo } from 'src/app/shared/interfaces/Grupo';
import { GrupoService } from '../services/grupo.service';
import { Arquivo } from 'src/app/shared/interfaces/Arquivo';
import { MessageService, SelectItem } from 'primeng/api';
import {CheckboxModule} from 'primeng/checkbox';
import { SimNao } from 'src/app/shared/enums/SimNao';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { tipoEtiqueta } from 'src/app/shared/enums/tipoEtiqueta';

const TAMANHO_MAX_ARQUIVO = 2000;
const TIPO_ACEITO = 'image';

@Component({
    templateUrl: './grupo.component.html',
    styleUrls: ['./grupo.styl'],
    providers: [
        GrupoService
    ]
})
export class GrupoComponent implements OnInit {

    idGrupo: number;
    grupo: Grupo;
    arquivo: Arquivo;
    simNaoSelect: SelectItem[];
    seMultiplaSelecao: boolean;

    @ViewChild('upload', { static: true })
    uploadInput: ElementRef<HTMLInputElement>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private grupoService: GrupoService,
        private router: Router,
        private messageService: MessageService
    ) {
        this.idGrupo = this.activatedRoute.snapshot.params.id;
    }

    ngOnInit() {
        if (this.idGrupo) {
            this.buscarGrupo();
        } else {
            this.grupo = {};
        }

		this.simNaoSelect = [
			{
				label: "Sim",
				value: "S"
			},
			{
				label: "Não",
				value: "N"
			}
		]

    }

    voltarGrupos() {
        this.router.navigate(['grupos'])
    }

    abrirUpload() {
        this.uploadInput.nativeElement.click();
    }
    
    salvarOuEditarGrupo() {
        if (this.validarCamposGrupo()) {
            return;
        }
        this.grupo.seMultiplaSelecao = this.seMultiplaSelecao ? SimNao.Sim : SimNao.Nao;
        this.grupoService.saveOrUpdate(this.grupo).subscribe(
            response => {
                this.voltarGrupos();
            }
        );
    }

    excluir() {
        this.grupoService.excluir(this.idGrupo).subscribe(
            response => {
                this.voltarGrupos();
            }
        );
    }   

    private buscarGrupo() {
        this.grupoService.buscarGrupo(this.idGrupo).subscribe(
            response => {
                this.grupo = response;
                this.seMultiplaSelecao = this.grupo.seMultiplaSelecao == SimNao.Sim ? true : false;
                console.log(response);
            }
        );
    }

    private validarCamposGrupo(): boolean {
        const grupo: Grupo = this.grupo;
        let erro = false;

        if ( !grupo.descricao || grupo.descricao === "") {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Descrição corretamente!`
            });
            erro = true;
        }

        return erro;
    }

}
