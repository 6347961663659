import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';

import { EnviarNotificacaoComponent } from './enviar-notificacao/enviar-notificacao.component';
import { HeaderComponent } from './header.component';
import { BarraPesquisaComponent } from './barra-pesquisa/barra-pesquisa.component';


@NgModule({
    declarations: [
        EnviarNotificacaoComponent,
        HeaderComponent,
        BarraPesquisaComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        DropdownModule,
        MultiSelectModule
    ],
    exports: [
        HeaderComponent,
        BarraPesquisaComponent,
        EnviarNotificacaoComponent
    ]
})
export class HeaderModule { }
