import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { LoginService } from '../login.service';

@Component({
	templateUrl: './esqueci-minha-senha.component.html',
	styleUrls: ['./esqueci-minha-senha.component.styl']
})
export class EsqueciMinhaSenhaComponent implements OnInit {

	esqueciForm: FormGroup;
	@ViewChild('btnEnviar', {static: true}) btnEnviar: ElementRef<HTMLButtonElement>;
	@ViewChild('senhaEnviada', {static: true}) pSenhaEnviada: ElementRef<HTMLParagraphElement>;

	constructor(
		private formBuilder: FormBuilder,
		private title: Title,
		private messageService: MessageService,
		private loginService: LoginService
	) {}

	ngOnInit() {
		this.title.setTitle('Esqueci minha senha | Nova Etiquetas');

		this.esqueciForm = this.formBuilder.group({
			email: ['', 
				[Validators.required, Validators.email]
			],
		});

	}

	esqueciSenha() {
		this.loginService.recuperarSenha(this.esqueciForm.get('email').value)
			.subscribe(
				response => {
					if(response) {
						this.btnEnviar.nativeElement.classList.add('enviado');
						this.btnEnviar.nativeElement.textContent = "ENVIADO";
						this.pSenhaEnviada.nativeElement.classList.add('ativo');
					}
				},
				error => {
					this.messageService.add({
						key: 'toast', 
						severity:'error', 
						summary: 'Erro', 
						detail: 'E-mail incorreto.'
					});
				}
			)
		;
	}

}
