import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ConfiguracaoDto } from 'src/app/shared/interfaces/dto/ConfiguracaoDto';


@Injectable()
export class ConfiguracaoService {

    constructor(private http: HttpClient) { }

    buscarConfiguracoes(): Observable<ConfiguracaoDto> {
        return this.http.get<ConfiguracaoDto>(environment.URL_BASE + "WS/Configuracao/Buscar");
    }

    saveOrUpdate(configuracaoDto:ConfiguracaoDto): Observable<HttpResponse<ConfiguracaoDto>> {
        return this.http.post<ConfiguracaoDto>(environment.URL_BASE + "WS/Configuracao/Salvar", configuracaoDto, {observe:'response'});
    }

}
