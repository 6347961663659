import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Integracao } from 'src/app/shared/interfaces/Integracao';

const API_URL = 'WS/Integracao';

@Injectable()
export class IntegracaoService {

    URL_BASE: string;

    constructor(private http: HttpClient) {
        this.URL_BASE = environment.URL_BASE;
    }

    buscarIntegracao(): Observable<Integracao> {
        return this.http.get<Integracao>(`${this.URL_BASE}${API_URL}`);
    }

    salvarIntegracao(integrcao: Integracao): Observable<Integracao> {
        return this.http.post<Integracao>(`${this.URL_BASE}${API_URL}`, integrcao);
    }

}