import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Login } from 'src/app/shared/interfaces/Login';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
	templateUrl: './acessar.component.html',
	styleUrls: ['./acessar.component.styl']
})
export class AcessarComponent implements OnInit {

	loginForm: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private title: Title,
		private router: Router,
		private messageService: MessageService,
		private loginService: LoginService
	) {}

	ngOnInit() {
		this.title.setTitle('Acessar | Nova Etiquetas');

		this.loginForm = this.formBuilder.group({
			usuario: ['', Validators.required],
			senha: ['', 
				[
					Validators.required,
					Validators.minLength(4),
					Validators.maxLength(20)
				]
			],
			seManterLogado: []
		});

	}

	fazerLogin() {
		const login = this.loginForm.getRawValue() as Login;
		this.loginService.autenticar(login)
			.subscribe(
				response => {
					if(response) {
						this.messageService.add({
							key: 'toast', 
							severity:'success', 
							summary: 'Sucesso', 
							detail: 'Você será redirecionado!'
						});
						this.router.navigate(['/']);
					}
				},
				error => {
					this.messageService.add({
						key: 'toast', 
						severity:'error', 
						summary: 'Erro', 
						detail: 'Usuário ou senha incorretos.'
					});
				}
			)
		;
	}

}
