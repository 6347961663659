import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SideBarComponent } from './side-bar.component';
import { MenuIntegracaoComponent } from './menu-integracao/menu-integracao.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule
    ],
    exports: [
        SideBarComponent
    ],
    declarations: [
        SideBarComponent,
        MenuIntegracaoComponent
    ],
    providers: [],
})
export class SideBarModule {}
