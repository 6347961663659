import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { Column } from 'src/app/shared/interfaces/Column';
import { Orcamento } from 'src/app/shared/interfaces/Orcamento';
import { OrcamentoService } from '../services/orcamento.service';
import { Arquivo } from 'src/app/shared/interfaces/Arquivo';
import { MessageService, SelectItem, DialogService } from 'primeng/api';
import { ItemOrcamento } from 'src/app/shared/interfaces/ItemOrcamento';
import { ItemComponent } from '../item-listagem/item-listagem.component';

const TAMANHO_MAX_ARQUIVO = 2000;
const TIPO_ACEITO = 'image';

@Component({
    templateUrl: './orcamento.component.html',
    styleUrls: ['./orcamento.styl'],
    providers: [
        OrcamentoService,
        DialogService
    ]
})
export class OrcamentoComponent implements OnInit {

    idOrcamento: number;
    orcamento: Orcamento;
    arquivo: Arquivo;
    simNaoSelect: SelectItem[];
    colunas: Column[];

    @ViewChild('upload', { static: true })
    uploadInput: ElementRef<HTMLInputElement>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private orcamentoService: OrcamentoService,
        private router: Router,
        private messageService: MessageService,
        public dialogService: DialogService
    ) {
        this.idOrcamento = this.activatedRoute.snapshot.params.id;
    }

    ngOnInit() {
        if (this.idOrcamento) {
            this.buscarOrcamento();
        } else {
            this.orcamento = {};
        }

		this.simNaoSelect = [
			{
				label: "Sim",
				value: "S"
			},
			{
				label: "Não",
				value: "N"
			}
		]
        this.colunas = [
            {
                field: 'produto.descricao',
                header: 'PRODUTO',
                format: this.formatarProduto
            },
            {
                field: 'id',
                header: 'MEDIDAS (LxA)',
                format: this.formatarMedidas
            },
            // {
            //     field: 'id',
            //     header: 'QUANTIDADE',
            // },
            {
                field: 'precoQuinzeATrinta',
                header: 'PREÇO ( de 16000 a 30000 pçs )',
                format: this.formatarPreco
            },
        ];
    }

    private formatarProduto(item: ItemOrcamento): string {
        return item.produto.descricao;
    }

    private formatarMedidas(item: ItemOrcamento): string {
        return item.larguraEtiqueta + "x" + item.tamanhoFundo;
    }

    private formatarPreco(item: ItemOrcamento): string {
        if (item.precoQuinzeATrinta != null) {
            return "R$ " + item.precoQuinzeATrinta.toString().replace(".", ",");
        }
        return null;
    }

    voltarOrcamentos() {
        this.router.navigate(['orcamentos'])
    }

    abrirUpload() {
        this.uploadInput.nativeElement.click();
    }

    verPdf(orcamentoPdf: Orcamento) {
        window.open(environment.URL_BASE + "WS/Orcamento/Pdf/?id=" + orcamentoPdf.id);
    }

    converterArquivo(event) {
        const component = this;
        const arquivo = event.target.files[0];

        const splitTipoArquivo = arquivo.type.split("/");

        if (splitTipoArquivo[0] != TIPO_ACEITO) {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Arquivo: ${arquivo.name} não é um formato válido`
            });
            return;
        }

        const tamanhoArquivo = (arquivo.size / 1024);

        if (tamanhoArquivo > TAMANHO_MAX_ARQUIVO) {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Arquivo: ${arquivo.name} é maior que 2MB`
            });
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(arquivo);

        reader.onload = (arquivoConvertido: any) => {
            const dados = arquivoConvertido.target.result.split("base64,");
            // this.orcamento.foto = {
            //     nome: arquivo.name,
            //     bytes: dados[1],
            //     tamanho: arquivo.size,
            // }
        }

        reader.onerror = (error) => {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Não foi possivel ler a imagem.`
            });
            console.log('Error: ', error);
        };
    }

    salvarOuEditarProduto() {
        if (this.validarCamposProduto()) {
            return;
        }
        this.orcamentoService.saveOrUpdate(this.orcamento).subscribe(
            response => {
                this.voltarOrcamentos();
            }
        );
    }

    private buscarOrcamento() {
        this.orcamentoService.buscarOrcamento(this.idOrcamento).subscribe(
            response => {
                this.orcamento = response;
                console.log(this.orcamento);
            }  
        );
    }

    private validarCamposProduto(): boolean {
        const produto: Orcamento = this.orcamento;
        let erro = false;
        return erro;
    }

    show(item: ItemOrcamento) {
        const ref = this.dialogService.open(ItemComponent, {
            width: '70%',
            contentStyle: {"max-height": "500px", "overflow": "auto", "background-color": "white"},
            baseZIndex: 9999,
            data: {idItem: item.id}
            
        });        

        ref.onClose.subscribe(() =>{                                                     
            
        });
    }

}
