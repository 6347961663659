import { Component, OnInit } from '@angular/core';
import { Column } from 'src/app/shared/interfaces/Column';
import { Cliente } from 'src/app/shared/interfaces/Cliente';
import { Subscription } from 'rxjs';
import { ClientesService } from '../services/clientes.service';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { formatarEndereco, formatarData } from 'src/app/shared/util/formatters';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';

@Component({
    templateUrl: './clientes.component.html',
    styleUrls: ['./clientes.styl'],
    providers: [
        ClientesService
    ]
})
export class ClientesComponent implements OnInit {

    colunas: Column[];
    clientesPagina: Paginacao<Cliente> = {};    
    filtro: FiltroBarraPesquisa = {};

    constructor(
        private clientesService: ClientesService
    ) { }

    ngOnInit() {
        this.colunas = [
            {
                field: 'dataInclusao',
                header: 'DATA',
                format: this.formatarData
            },
            {
                field: 'nome',
                header: 'NOME DO CLIENTE'
            },
            {
                field: 'endereco',
                header: 'ENDEREÇO',
                format: this.formatarEndereco
            },
            {
                field: 'telefone',
                header: 'TELEFONE',
            },
            {
                field: 'email',
                header: 'E-MAIL',
            },
        ];
        this.buscarClientes(this.filtro, 0);
    }

    loadDataOnScroll(event) {
        this.buscarClientes(this.filtro, event.first / event.rows);
    }

    private formatarEndereco(cliente: Cliente): string {
        return formatarEndereco(cliente.endereco);
    }

    private formatarData(cliente: Cliente): string {
        return formatarData(cliente.dataHoraInclusao);
    }

    public buscarClientes = (filtro: FiltroBarraPesquisa, pagina: number) => {
        this.clientesService.buscarClientes(filtro, pagina).subscribe(
            response => {
                this.clientesPagina = response;
            }, error => {
                console.log(error)
            }
        );
    }

}
