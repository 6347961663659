import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../token/token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private tokenService: TokenService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let token: string = this.tokenService.getToken();
        if (token) {
            const cloned = request.clone({
                headers: request.headers.set("Authorization", "Bearer " + token),
            });
            return next.handle(cloned);
        }
        else {
            return next.handle(request);
        }
    }
}