import { Component, OnInit } from '@angular/core';
import { Column } from 'src/app/shared/interfaces/Column';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Orcamento } from 'src/app/shared/interfaces/Orcamento';
import { OrcamentoService } from '../services/orcamento.service';
import { Router } from '@angular/router';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { SimNao } from 'src/app/shared/enums/SimNao';

@Component({
    templateUrl: './orcamentos.component.html',
    styleUrls: ['./orcamentos.styl'],
    providers: [
        OrcamentoService
    ]
})
export class OrcamentosComponent implements OnInit {

    colunas: Column[];
    orcamentosPagina: Paginacao<Orcamento> = {};
    orcamento$: Subscription;
    filtro: FiltroBarraPesquisa = {};

    constructor(
        private orcamentoService: OrcamentoService,
        private router: Router
    ) { }

    ngOnInit() {
        this.colunas = [
            {
                field: 'dataInclusao',
                header: 'DATA'
            },
            {
                field: 'hora',
                header: 'HORÁRIO',
            },
            {
                field: 'numeroOrcamento',
                header: 'ORÇAMENTO',
            },
            {
                field: 'nomeCliente',
                header: 'CLIENTE',
            },
            {
                field: 'nomeRepresentante',
                header: 'REPRESENTANTE',
            },
        ];
        this.buscarOrcamentos(this.filtro, 0);
    }

    loadDataOnScroll(event) {
        this.buscarOrcamentos(this.filtro, event.first / event.rows);
    }

    verOrcamento(representante: Orcamento) {
        this.router.navigate(['orcamento', representante.id]);
    }

    
    verPdf(orcamentoPdf: Orcamento) {
        window.open(environment.URL_BASE + "WS/Orcamento/Pdf/?id=" + orcamentoPdf.id);
    }

    public buscarOrcamentos = (filtro: FiltroBarraPesquisa, pagina: number) => {
        filtro.sePedidoEmitido = SimNao.Nao;
        this.orcamentoService.buscarOrcamentos(filtro, pagina).subscribe(
            response => {
                this.orcamentosPagina = response;
                console.log(this.orcamentosPagina);
            }
        );
    }

}
