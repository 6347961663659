import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Observable } from 'rxjs';
import { Log } from 'src/app/shared/interfaces/Log';
import { environment } from 'src/environments/environment';
import { FiltroLog } from 'src/app/shared/interfaces/FiltroLog';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';

@Injectable()
export class LogService {

    constructor(private http: HttpClient) { }

    buscarLogs(filtro: FiltroBarraPesquisa, pagina: number): Observable<Paginacao<Log>> {
        return this.http.post<Paginacao<Log>>(environment.URL_BASE + "WS/Queue/Filtrar?page="+pagina +'&sortField=status&sortDirection=ASC', filtro);
    }

}
