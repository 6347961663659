import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Opcional } from 'src/app/shared/interfaces/Opcional';
import { OpcionalService } from '../services/opcional.service';
import { MessageService, SelectItem, DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { ItemOrcamento } from 'src/app/shared/interfaces/ItemOrcamento';
import { OrcamentoService } from '../services/orcamento.service';
import { Column } from 'src/app/shared/interfaces/Column';
import { ItemOrcamentoOpcional } from 'src/app/shared/interfaces/ItemOrcamentoOpcional';
import { AdicionalFiguraItemOrcamento } from 'src/app/shared/interfaces/AdicionalFiguraItemOrcamento';

const TAMANHO_MAX_ARQUIVO = 2000;
const TIPO_ACEITO = 'image';

@Component({
    templateUrl: './item-listagem.component.html',
    styleUrls: ['./item-listagem.styl'],
    providers: [
        OrcamentoService,
    ]
})
export class ItemComponent implements OnInit {

    idItem: number;
    item: ItemOrcamento;
    colunasOpcionais: Column[];
    colunasFiguras: Column[];

    constructor(
        private activatedRoute: ActivatedRoute,
        private orcamentoService: OrcamentoService,
        private ref: DynamicDialogRef,
        config: DynamicDialogConfig
    ) {
        this.idItem = config.data.idItem;
    }

    ngOnInit() {
        console.log("teste")
        this.buscarDetalhesItemOrcamento();

        this.colunasOpcionais = [
            {
                field: 'descricao',
                header: 'OPCIONAL',
                format: this.formatarOpcional
            },
            {
                field: 'opcional.porcentagemAcrescimoOpcional',
                header: 'PORCENTAGEM ACRÉSCIMO',
                format: this.formatarPorcentagem
            },
            {
                field: 'opcional.valorAcrescimoOpcional',
                header: 'VALOR ACRÉSCIMO',
                format: this.formatarValor
            },
        ];

        this.colunasFiguras = [
            // {
            //     field: 'figura.tituloFigura',
            //     header: 'FIGURA',
            //     format: this.formatarFigura
            // },
            {
                field: 'figura.tamanhoFigura',
                header: 'TAMANHO',
                format: this.formatarTamanho
            },
        ];

    }

    private formatarOpcional(itemOpcional: ItemOrcamentoOpcional): string {
        return itemOpcional.descricaoOpcional;
    }

    private formatarPorcentagem(itemOpcional: ItemOrcamentoOpcional): number {
        return itemOpcional.porcentagemAcrescimoOpcional;
    }

    private formatarValor(itemOpcional: ItemOrcamentoOpcional): number {
        return itemOpcional.valorAcrescimoOpcional;
    }

    private formatarFigura(figura: AdicionalFiguraItemOrcamento): string {
        return figura.tituloFigura;
    }

    private formatarTamanho(figura: AdicionalFiguraItemOrcamento): number {
        return figura.tamanhoFigura;
    }

    private buscarDetalhesItemOrcamento() {
        this.orcamentoService.buscarDetalhesItemOrcamento(this.idItem).subscribe(
            response => {
                this.item = response;
            }  
        );
    }

    fechar() {
        this.ref.close();
    }

}
