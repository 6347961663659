import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Cliente } from 'src/app/shared/interfaces/Cliente';
import { ResumoDto } from 'src/app/shared/interfaces/dto/ResumoDto';
import { Log } from 'src/app/shared/interfaces/Log';
import { environment } from 'src/environments/environment';
import { ClientesService } from '../services/clientes.service';
import { LogService } from '../services/log.service';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { OrcamentoService } from '../services/orcamento.service';
import { Orcamento } from 'src/app/shared/interfaces/Orcamento';
import { ClienteDto } from 'src/app/shared/interfaces/dto/ClienteDto';
import { OrcamentoDto } from 'src/app/shared/interfaces/dto/OrcamentoDto';


@Component({
    templateUrl: './resumo.component.html',
    styleUrls: ['./resumo.styl'],
    providers: [
        ClientesService
    ]
})
export class ResumoComponent implements OnInit, OnDestroy {

    resumoDto: ResumoDto = {};
    resumoDto$: Subscription;
    clientes: ClienteDto[] = [];
    orcamentos: OrcamentoDto[] = [];

    constructor(
        private http: HttpClient
    ) { }

    ngOnInit() {
        this.buscarResumo();
    }

    ngOnDestroy() {
        // this.resumoDto$.unsubscribe();
        // this.logs$.unsubscribe();
        // this.clientes$.unsubscribe();
    }

    private buscarResumo() {
        this.http.get(environment.URL_BASE + "WS/Resumo").subscribe(response => {
            this.resumoDto = response;
            this.clientes = this.resumoDto.clientes;
            this.orcamentos = this.resumoDto.orcamentos;
        })
    }

}
